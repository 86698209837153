import React, { useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import '../css/SuperAdminPanel.css'; // Import the styles
import UserList from './UserList';
import PaymentHistory from './PaymentHistory';
import RolePermission from './RolePermission';
import ProtectedRoute from './ProtectedRoute';

const SuperAdminPanel = () => {
 
    const navigate = useNavigate();
    const handleLogout = () => {
        // Clear the authentication token or session-related information
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('isSuperAdmin');
        
    
        // Redirect the user to the login page
        navigate('/login');
      };

  return (
    <div className={`super-admin-panel `}>
      <nav className="sidebar">
        <div className="logo-container">
          <h1 className="app-name">Flash Card App</h1>
        </div>
       
        <ul>
          <li>
            <Link to="/super-admin/user-list">User List</Link>
          </li>
          <li>
            <Link to="/super-admin/payment-history">Payment History</Link>
          </li>
          <li>
            <Link to="/super-admin/role-permission">Role and Permission</Link>
          </li>
          <li>
            <Link to="/login" onClick={handleLogout}>Logout</Link>
          </li>
        </ul>
      </nav>

      <div className="content">
        {/* Use only Routes for nested components */}
        <Routes>
        {/* <ProtectedRoute path="user-list" element={<UserList />} /> */}
          <Route path="user-list" element={<UserList />} />
          <Route path="payment-history" element={<PaymentHistory />} />
          <Route path="role-permission" element={<RolePermission />} />
        </Routes>
      </div>
    </div>
  );
};

export default SuperAdminPanel;
