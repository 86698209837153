import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import '../css/UserList.css';
import { updateUserAdminStatus, updateUsers } from '../actions'; // Import your Redux actions

const UserList = () => {
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const isSuperAdmin = sessionStorage.getItem('isSuperAdmin');
  const users = useSelector((state) => state.users);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const authToken = sessionStorage.getItem('authToken');

        const apiUrl = process.env.REACT_APP_API_URL_LIVE;
        const response = await fetch(`${apiUrl}/users1`, {
        // const response = await fetch('http://localhost:5000/users1', {
          method: 'POST',
          body: JSON.stringify({ isSuperAdmin }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          dispatch(updateUsers(data)); // Dispatch action to update Redux state
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error during user data fetch:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [dispatch, isSuperAdmin]);

  useEffect(() => {
    // Initialize DataTable when users data changes and loading is complete
    if (!loading) {
      initializeDataTable(users);
    }
  }, [users, loading]);

  const initializeDataTable = (data) => {
    if (tableRef.current) {
      $(tableRef.current).DataTable({
        data,
        columns: [
          { title: 'Sr. No.', data: 'id' },
          { title: 'User Name', data: 'username' },
          { title: 'Email', data: 'email' },
          { title: 'Google ID', data: 'googleId' },
          {
            title: 'isAdmin',
            data: 'isAdmin',
            render: function (data, type, row) {
              return `<button class="toggle-button" data-user-id="${row.id}" data-is-admin="${data}">${data}</button>`;
            },
          },
        ],
        destroy: true,
      });
  
      // Attach click event to toggle isAdmin status
      $(tableRef.current).on('click', '.toggle-button', function () {
        const userId = $(this).data('user-id');
        const currentIsAdmin = $(this).data('is-admin');
        const newIsAdmin = !currentIsAdmin ? !currentIsAdmin : false;
  
        // Update the button text
        $(this).text(newIsAdmin);
  
        // Dispatch the Redux action to update the user's isAdmin status
        dispatch(updateUserAdminStatus(userId, newIsAdmin, isSuperAdmin));
      });
    }
  };
  

  // Function to update server user's isAdmin status
  const updateServerUserAdminStatus = async (userId, isAdmin) => {
    try {
      const authToken = sessionStorage.getItem('authToken');

      const apiUrl = process.env.REACT_APP_API_URL_LIVE;
        const response = await fetch(`${apiUrl}/users/${userId}/admin`, {
    //   const response = await fetch(`http://localhost:5000/users/${userId}/admin`, {
        method: 'POST',
        body: JSON.stringify({ isAdmin, isSuperAdmin: isSuperAdmin }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        console.error('Failed to update user admin status on the server');
      }
    } catch (error) {
      console.error('Error during server update:', error);
    }
  };

  return (
    <div className="user-list-container">
      <h2>User List</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table ref={tableRef} className="user-list table table-bordered">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Google ID</th>
              <th>isAdmin</th>
            </tr>
          </thead>
        </table>
      )}
    </div>
  );
};

export default UserList;
