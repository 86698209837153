// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/css/UserList.css */
.user-list-container {
    padding: 20px;
  }
  
  .user-list {
    list-style: none;
    padding: 0;
  }
  
  .user-list li {
    margin-bottom: 10px;
  }
  
  .user-list li strong {
    color: #3498db; /* Blue color for user names */
  }
  


  /* UserList.css */
.toggle-button {
    color: white;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .toggle-button[data-is-admin="true"] {
    background-color: green;
  }
  
  .toggle-button[data-is-admin="false"] {
    background-color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/UserList.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc,EAAE,8BAA8B;EAChD;;;;EAIA,iBAAiB;AACnB;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":["/* src/css/UserList.css */\r\n.user-list-container {\r\n    padding: 20px;\r\n  }\r\n  \r\n  .user-list {\r\n    list-style: none;\r\n    padding: 0;\r\n  }\r\n  \r\n  .user-list li {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .user-list li strong {\r\n    color: #3498db; /* Blue color for user names */\r\n  }\r\n  \r\n\r\n\r\n  /* UserList.css */\r\n.toggle-button {\r\n    color: white;\r\n    padding: 5px 10px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .toggle-button[data-is-admin=\"true\"] {\r\n    background-color: green;\r\n  }\r\n  \r\n  .toggle-button[data-is-admin=\"false\"] {\r\n    background-color: red;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
