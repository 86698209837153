import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import SuperAdminPanel from './components/SuperAdminPanel';
import { Provider } from 'react-redux';
import store from './store'; // Import your Redux store
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          {/* <Route path="/super-admin/*" element={<SuperAdminPanel />} /> */}
          <Route path="/super-admin/*" element={<SuperAdminPanel />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
