// reducer.js
const initialState = {
    users: [],
  };
  
//   const reducer = (state = initialState, action) => {
//     switch (action.type) {
    //   case 'UPDATE_USER_ADMIN_STATUS':
    //     return {
    //       ...state,
    //       users: state.users.map((user) =>
    //         user.id === action.payload.userId ? { ...user, isAdmin: action.payload.isAdmin } : user
    //       ),
    //     };
//       // Handle other cases if needed
//       default:
//         return state;
//     }
//   };

// reducer.js
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_USERS':
        return {
          ...state,
          users: action.payload,
        };

      // Handle other cases if needed
      case 'UPDATE_USER_ADMIN_STATUS':
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.userId ? { ...user, isAdmin: action.payload.isAdmin } : user
        ),
      };

      default:
        return state;
    }
  };
  
  
  export default reducer;
  