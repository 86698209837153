// actions.js
export const updateUsers = (users) => {
    console.log('Updated Users:', users); // Add this line for debugging
    return {
      type: 'UPDATE_USERS',
      payload: users,
    };
  };
  
  

// actions.js
export const updateUserAdminStatus = (userId, isAdmin, isSuperAdmin) => async (dispatch) => {
    try {
      const authToken = sessionStorage.getItem('authToken');
  
      const apiUrl = process.env.REACT_APP_API_URL_LIVE;
      const response = await fetch(`${apiUrl}/users/${userId}/admin`, {
    //   const response = await fetch(`http://localhost:5000/users/${userId}/admin`, {
        method: 'POST',
        body: JSON.stringify({ isAdmin, isSuperAdmin }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      if (response.ok) {
        dispatch({
          type: 'UPDATE_USER_ADMIN_STATUS',
          payload: { userId, isAdmin },
        });
  
        console.log('Updated data for user ID:', userId);
      } else {
        console.error('Failed to update user isAdmin status');
      }
    } catch (error) {
      console.error('Error updating user isAdmin status:', error);
    }
  };
  
  

  // Thunk action creator
export const toggleUserAdminStatus = (userId, currentIsAdmin) => async (dispatch) => {
    try {
      // Perform any asynchronous operations here
      const updatedUser = await updateUserAdminStatus(userId, !currentIsAdmin);
  
      // Dispatch a regular action with the updated user
      dispatch({
        type: 'TOGGLE_USER_ADMIN_STATUS_SUCCESS',
        payload: { userId, updatedUser },
      });
    } catch (error) {
      // Dispatch an action for error handling
      dispatch({
        type: 'TOGGLE_USER_ADMIN_STATUS_FAILURE',
        payload: { error },
      });
    }
  };
  