import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Call the login API endpoint
      const apiUrl = process.env.REACT_APP_API_URL_LIVE;
      const response = await fetch(`${apiUrl}/super-admin-login`, {
    //   const response = await fetch('http://localhost:5000/super-admin-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }), // Include the user role in the request body
      });
      console.log(response.ok);
      if (response.ok) {
        const data = await response.json();
        // Assume the API returns a token on successful login
        const token = data.token;
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('isSuperAdmin', data.user.isSuperAdmin);

        // // Call the onLogin callback with the token
        // onLogin(token);

        // Redirect to the dashboard
        navigate('/super-admin');
      } else {
        setError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('An error occurred during login.');
    }
  };
  return (
    <div className="login-container">
      <h2 className="login-title">Flash Card</h2>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="form-group">
        <label>Password:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div>
        <button className="login-button" onClick={handleLogin}>
          Login
        </button>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Login;