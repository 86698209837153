// ProtectedRoute.js
import React from 'react';
import { useNavigate, Route } from 'react-router-dom';

const ProtectedRoute = ({ element: Element, ...props }) => {
  const navigate = useNavigate();
  const authToken = sessionStorage.getItem('authToken');

  if (!authToken) {
    // Redirect to the login page if authToken is not present
    navigate('/login');
    return null;
  }

  return <Route {...props} element={<Element />} />;
};

export default ProtectedRoute;
